<template>
  <component :is="this.$route.meta.layout || layout">
    <slot />
  </component>
</template>

<script>
import { defineComponent, shallowRef } from "vue";
import BaseLayout from "./layouts/BaseLayout";

export default defineComponent({
  name: "App",
  data: () => ({
    layout: shallowRef(BaseLayout),
  }),
});
</script>

<style>
@font-face {
  font-family: "Museo Sans 100";
  src: url("./assets/fonts/MuseoSansRounded-100.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Museo Sans 300";
  src: url("./assets/fonts/MuseoSansRounded-300.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Museo Sans 500";
  src: url("./assets/fonts/MuseoSansRounded-500.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Museo Sans 900";
  src: url("./assets/fonts/MuseoSansRounded-900.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Museo Sans 1000";
  src: url("./assets/fonts/MuseoSansRounded-1000.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Museo Sans 700";
  src: url("./assets/fonts/MuseoSansRounded-700.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
*::-webkit-scrollbar-track {
  background-color: lightgray;
}
*::-webkit-scrollbar-thumb {
  background-color: #000000;
}

/* Page Fade Transition Styling*/
.scale-enter-active,
.scale-leave-active {
  transition: all 0.5s ease;
}
.scale-enter-from,
.scale-leave-to {
  opacity: 0;
  transform: scale(0.9);
}
</style>
