export class UpdateGetlistDTO extends FormData {
  constructor(getlist) {
    super()
    this.append('id', getlist.id)
    this.append('title', getlist.title)
    this.append('event_date', getlist.eventDate)
    this.append('message', getlist.message)
    this.append('privacy', getlist.privacy)
    
    if (getlist.image && typeof (getlist.image) !== 'string')
      this.append('image', getlist.image)
  }
}