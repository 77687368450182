import { httpService } from "@/services/http.service"
import { GetList } from "@/models/getlist"
import { Pagination } from "@/models/pagination"

const defaultState = () => {
  return {
    list: Pagination.prototype.createEmpty(),
    publicList: Pagination.prototype.createEmpty(),
  }
}

const state = defaultState()

const actions = {
  create: async ({ dispatch }, data) => {
    const response = await httpService.post("getlists", data)
    await dispatch("getAll")
    return response.data.message;
  },
  getAll: async ({ commit }) => {
    const response = await httpService.get("getlists", { params: { privacy: 1 } })
    commit("SET_GETLISTS", response.data.data)
  },
  getPublicGetlists: async ({ commit }) => {
    const response = await httpService.get("getlists", { params: { privacy: 0 } })
    commit("SET_PUBLIC_GETLISTS", response.data.data)
  },
  get: async (context, data) => {
    const response = await httpService.get(`getlists/${data}`, { params: { _hideAxiosMessage_: true } })
    return new GetList(response.data.data)
  },
  update: async ({ dispatch }, data) => {
    const response = await httpService.post(`getlists/${data.get('id')}`, data)
    await dispatch("getAll")
    return response.data.message
  },
  delete: async ({ dispatch }, data) => {
    const response = await httpService.delete(`getlists/${data}`)
    await dispatch("getAll")
    return response.data.message
  }
}

const mutations = {
  SET_GETLISTS: (state, paginatedGetLists) => {
    const paginatedList = new Pagination(paginatedGetLists, 'getlists/getAll')
    paginatedList.data = paginatedList.data.map((getlist) => new GetList(getlist))
    state.list = paginatedList
  },
  SET_PUBLIC_GETLISTS: (state, paginatedGetLists) => {
    const paginatedList = new Pagination(paginatedGetLists, 'getlists/getAll')
    paginatedList.data = paginatedList.data.map((getlist) => new GetList(getlist))
    state.publicList = paginatedList
  },
  CLEAR: (state) => {
    state.list = Pagination.prototype.createEmpty()
  },
  RESET: (state) => {
    Object.assign(state, defaultState())
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}