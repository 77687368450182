const state = {
  loading: false
}
const actions = {
  startLoading: async ({ commit }) => {
    commit('SET_LOADING', true)
  },

  stopLoading: async ({ commit }) => {
    commit('SET_LOADING', false)
  }
}
const mutations = {
  SET_LOADING: (state, loadState) => {
    state.loading = loadState
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}