
export class User {
  constructor(user) {
    this.id = user.id;
    this.firstName = user.first_name;
    this.lastName = user.last_name;
    this.username = user.username;
    this.emailAddress = user.email_address;
    this.emailAddressVerifiedAt = user.email_address_verified_at;
    this.phoneNumberVerifiedAt = user.phone_number_verified_at;
    this.phoneNumber = user.phone_number;
    this.kycVerified = user.kyc_verified;
    this.avatarUrl = user.avatar_url;
    this.dateOfBirth = new Date(user.date_of_birth).toISOString().split('T')[0];
    this.createdAt = user.created_at;
    this.updatedAt = user.updated_at;
  }

  get fullName() {
    return `${this.firstName} ${this.lastName}`
  }

  get formattedDateOfBirth() {
    return new Date(this.dateOfBirth).toISOString().split('T')[0]
    // return new Date(this.dateOfBirth).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })
  }
}