import { Transaction } from "@/models/transaction"
import { VirtualCard } from "@/models/virtual-card"
import { httpService } from "@/services/http.service"

const defaultState = () => {
  return {
    card: null,
    transactions: []
  }
}

const state = defaultState();

const actions = {
  create: async ({ dispatch }) => {
    const response = await httpService.post("virtual-card")
    await dispatch("get")
    return response.data.message;
  },
  get: async ({ commit }) => {
    const response = await httpService.get(`virtual-card`, { params: { _hideAxiosMessage_: true, reveal: 1 } })
    commit("SET_CARD", response.data.data)
  },
  getTransactions: async ({ commit }, data) => {
    const response = await httpService.get(`virtual-card/transactions`, { params: { data, _hideAxiosMessage_: true } })
    commit("SET_TRANSACTIONS", response.data.data)
  },
  fund: async ({ dispatch }, data) => {
    const response = await httpService.put(`virtual-card`, data)
    dispatch("get")
    return response.data.message
  },
  delete: async ({ dispatch }, data) => {
    const response = await httpService.delete(`virtual-card`, { params: { data } })
    return response.data.data
  },
}

const mutations = {
  SET_CARD: (state, card) => {
    state.card = new VirtualCard(card)
  },
  SET_TRANSACTIONS: (state, transactions) => {
    state.transactions = transactions.map((transaction) => new Transaction(transaction))
  },
  RESET: (state) => {
    Object.assign(state, defaultState())
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}