<template>
  <div>
    <Loader />
    <Header class="
    w-full
    sticky bg-white top-0 z-10" />

    <div
      class="
        max-w-screen-xl
        m-auto
        w-11/12
        lg:grid lg:grid-cols-8 lg:gap-10
        items-start
      "
    >
      <div class="col-span-2 sticky top-36 z-10">
        <AppLayoutLinks />
      </div>
      <div class="w-full col-span-6">
        <router-view v-slot="{ Component }">
          <transition name="scale" mode="out-in">
            <component :is="Component"></component>
          </transition>
        </router-view>
      </div>
    </div>
  </div>
</template>

<script>
import NotificationPanel from "@/components/NotificationPanel.vue";
import Header from "@/components/Header.vue";
import Loader from "@/components/Loader.vue";
import AppLayoutLinks from "@/components/AppLinks.vue";
import { ref } from "@vue/reactivity";
import { useStore } from "vuex";
import { computed } from "@vue/runtime-core";

export default {
  components: {
    Header,
    Loader,
    AppLayoutLinks,
    NotificationPanel,
  },
  setup() {
    const store = useStore();

    const openPanel = ref(false);
    const user = computed(() => store.state.auth.user);
    const loading = computed(() => store.state.loading.loading);

    return { openPanel, user, loading };
  },
};
</script>

<style></style>
