import { useToast } from "vue-toastification";

const toast = useToast()

const info = (message) => {
  toast.info(message)
}

const success = (message) => {
  toast.success(message)
}

const error = (message) => {
  toast.error(message)
}

const warning = (message) => {
  toast.warning(message)
}

export default { info, success, error, warning }