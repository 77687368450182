import { Pagination } from "@/models/pagination";
import { Transaction } from "@/models/transaction";
import { httpService } from "@/services/http.service"

const defaultState = () => {
  return {
    list: Pagination.prototype.createEmpty()
  }
}

const state = defaultState();

const actions = {
  getAll: async ({ commit }) => {
    const response = await httpService.get("transactions")
    commit('SET_TRANSACTIONS', response.data.data)
  },
  get: async ({ commit }, data) => {
    const response = await httpService.get(`transactions/${data}`)
    return new Transaction(response.data.data)
  },
}

const mutations = {
  SET_TRANSACTIONS: (state, transactions) => {
    const paginatedList = new Pagination(transactions, 'transactions/getAll')
    paginatedList.data = paginatedList.data.map((transaction) => new Transaction(transaction))
    state.list = paginatedList
  },
  RESET: (state) => {
    Object.assign(state, defaultState())
  }
}


export default {
  namespaced: true,
  state,
  actions,
  mutations
}