
export class GetList {
  constructor(getlist) {
    this.id = getlist.id;
    this.title = getlist.title;
    this.wishes = getlist.wishes;
    this.message = getlist.message;
    this.privacy = getlist.privacy ? 1 : 0;
    this.imageUrl = getlist.image_url;
    this.items = getlist.items ? getlist.items.map((item) => new Item(item)) : null;
    this.eventDate = new Date(getlist.event_date).toISOString().split('T')[0];
    this.createdAt = getlist.created_at;
    this.deletedAt = getlist.deleted_at;
    this.updatedAt = getlist.updated_at;
  }
}

class Item {
  constructor(item) {
    this.id = item.id;
    this.details = item.details;
    this.name = item.name;
    this.price = Number(item.price);
    this.quantity = item.quantity;
    this.status = item.status;
    this.contributors = item.contributors ? item.contributors.map((contributor) => new Contributor(contributor)) : [];
    this.imageUrl = item.image_url;
    this.createdAt = item.created_at;
    this.deletedAt = item.deleted_at;
    this.updatedAt = item.updated_at;
  }

  get givers() {
    return this.contributors.length
  }

  get amountContributed() {
    return this.contributors.reduce((total, { amount }) => { return +total + +amount }
      , 0)
  }

  get amountLeft() {
    return this.price - this.amountContributed
  }

  get progress() {
    return (this.amountContributed / this.price) * 100
  }
}

class Contributor {
  constructor(contributor) {
    this.id = contributor.id;
    this.type = contributor.type;
    this.amount = contributor.amount;
    this.fullName = contributor.full_name;
    this.emailAddress = contributor.email_address;
    this.phoneNumber = contributor.phone_number;
    this.createdAt = contributor.created_at;
    this.deletedAt = contributor.deleted_at;
    this.updatedAt = contributor.updated_at;
  }
}