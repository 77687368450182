import store from "@/store";
import axios from "axios";
import authService from "./auth.service"
import notificationService from "./notification.service";

const config = {
  baseURL: 'https://api.getly.app/api/v1',
  headers: {
    'Content-Type': 'application/json'
  }
}

let activeRequests = 0;

const httpService = axios.create(config);

const authInterceptor = (config) => {
  if (!navigator.onLine) {
    notificationService.error('Internet disconnected');
    return Promise.reject(config)
  }

  const token = authService.getToken();
  const hideLoader = config.params?._hideLoader_ ? true : false;

  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }

  if (!hideLoader) {
    if (activeRequests == 0) {
      store.dispatch('loading/startLoading');
    }
  }


  if (!hideLoader)
    activeRequests++

  return config
}

httpService.interceptors.request.use(authInterceptor)

httpService.interceptors.response.use(
  (response) => {
    const hideLoader = response.config?.params?._hideLoader_ ? true : false;
    if (!hideLoader)
      activeRequests--

    if (activeRequests == 0) {
      store.dispatch('loading/stopLoading');
    }
    return response
  },
  (error) => {
    const hideLoader = error.config?.params?._hideLoader_ ? true : false;
    if (!hideLoader)
      activeRequests--

    if (activeRequests == 0) {
      store.dispatch('loading/stopLoading');
    }
    const code = parseInt(error.response && error.response.status);
    const hideMessage = error.config?.params?._hideAxiosMessage_ ? true : false;
    const messages = error.response?.data?.errors;
    const message = error.response?.data?.message;

    if (code && !hideMessage) {
      if (messages) {
        Object.values(messages).forEach(([message]) => {
          notificationService.error(message)
        })
      } else if (message) notificationService.error(message)
    }

    if (!code) {
      // notificationService.error("Internet disconnected")
    }

    return Promise.reject(error)
  }
)

export { httpService }