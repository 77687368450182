import authService from "@/services/auth.service";
import axios from "axios";
import { createStore } from "vuex";
import auth from './modules/auth';
import gifts from './modules/gifts';
import wallet from './modules/wallet';
import loading from './modules/loading';
import getlists from './modules/getlists';
import giftCards from './modules/gift-cards';
import virtualCard from './modules/virtual-card';
import transactions from './modules/transactions';
import virtualAccounts from './modules/virtual-accounts';

axios.defaults.baseURL = 'https://api.getly.app/api/v1'

export default createStore({
  actions: {
    resetState: ({ commit }) => {
      commit('auth/RESET')
      commit('wallet/RESET')
      commit('getlists/RESET')
      commit('giftCards/RESET')
      commit('virtualCard/RESET')
      commit('transactions/RESET')
      commit('virtualAccounts/RESET')
    }
  },

  getters: {
    isLoggedIn: () => !!authService.getToken(),
  },

  modules: {
    auth,
    gifts,
    wallet,
    loading,
    getlists,
    giftCards,
    virtualCard,
    transactions,
    virtualAccounts,
  }
})