export class Pagination {
  constructor(pagination, store_action) {
    this.data = pagination['data'] || [];
    this.from = pagination['from'] || null;
    this.total = pagination['total'] || null;
    this.per_page = pagination['per_page'] || null;
    this.last_page = pagination['last_page'] || null;
    this.current_page = pagination['current_page'] || null;
    this.next_page_url = pagination['next_page_url'] || null;
    this.prev_page_url = pagination['prev_page_url'] || null;
    this.store_action = store_action || '';
  }

  createEmpty() {
    return new Pagination({ data: [] });
  }
}