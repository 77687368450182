import { VirtualAccount } from "@/models/virtual-account";
import { httpService } from "@/services/http.service"

const defaultState = () => {
  return {
    accountDetails: null
  }
}

const state = defaultState();

const actions = {
  create: async ({ dispatch }, data) => {
    const response = await httpService.post("virtual-account", data)
    await dispatch('get')
    await dispatch('auth/verifyUser', { root: true })
    return response.data.message;
  },
  get: async ({ commit }, options) => {
    const response = await httpService.get("virtual-account", { params: { _hideAxiosMessage_: true, _hideLoader_: true } })
    commit('SET_ACCOUNT_DETAILS', response.data.data)
  },
}

const mutations = {
  SET_ACCOUNT_DETAILS: (state, virtualAccount) => {
    state.accountDetails = new VirtualAccount(virtualAccount)
  },
  RESET: (state) => {
    Object.assign(state, defaultState())
  }
}


export default {
  namespaced: true,
  state,
  actions,
  mutations
}