import { httpService } from "@/services/http.service"
import { Gift } from "@/models/gift"

const defaultState = () => {
  return {}
}

const state = defaultState()

const actions = {
  create: async ({ dispatch }, data) => {
    const response = await httpService.post("gifts", data)
    return response.data.message;
  },
  get: async (context, data) => {
    const response = await httpService.get(`gifts/${data}`)
    return new Gift(response.data.data)
  },
  update: async ({ dispatch }, data) => {
    const response = await httpService.post(`gifts/${data.get('id')}`, data)
    return response.data.message
  },
  contribute: async ({ dispatch }, data) => {
    const response = await httpService.post(`contribute/${data.id}`, data)
    return response.data.data.payment_link
  },
  delete: async ({ dispatch }, data) => {
    const response = await httpService.delete(`gifts/${data}`)
    return response.data.message
  }
}

const mutations = {
  RESET: (state) => {
    Object.assign(state, defaultState())
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}