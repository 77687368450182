<template>
  <div
    class="bg-white rounded-xl p-6 md:w-80 w-64 shadow-xl text-standard-grey absolute top-0 md:left-0 right-0 transform translate-y-16 md:-translate-x-24 translate-x-0"
  >
    <div class="text-2xl mb-8 font-sans-700">Notification</div>

    <div class="notification h-96 overflow-hidden overflow-y-auto lg:pr-6 pr-2">
      <div class="flex items-start mb-6" v-for="item in 5" :key="item">
        <div class="mr-4">
          <img src="@/assets/images/notification.svg" alt="" />
        </div>
        <div class="pb-6 border-b border-light-grey border-opacity-50 text-sm">
          <div class="font-sans-500">
            It’s Tope Agboma’s birthday on Friday, 19th October.
          </div>
          <div class="font-sans-700">Find them a gift now.</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.notification::-webkit-scrollbar {
  width: 10px;
  height: 100%;
  background: none;
  border-radius: 100px;
}
.notification::-webkit-scrollbar-thumb {
  background: #898a8d;
  border-radius: 100px;
  width: 100%;
}
</style>
