<template>
  <div>
    <Loader />
    <Header />
    <div class="max-w-screen-xl m-auto w-11/12 md:flex items-start">
      <div>
        <div
          v-if="this.$route.name == 'Getlist' || this.$route.name == 'Wishlist'"
          class="w-min lg:mb-0 mb-6"
        >
          <div class="flex items-start mr-10" v-if="getlist">
            <div @click="goToGetlists" class="mr-6 w-6 h-6 mt-4 cursor-pointer">
              <img
                class="w-full h-full object-contain"
                src="@/assets/images/back.svg"
                alt=""
              />
            </div>
            <div>
              <div
                v-if="getlist.imageUrl"
                class="
                  w-40
                  rounded-sm
                  overflow-hidden
                  border-2 border-getly-yellow
                "
              >
                <img
                  :src="getlist.imageUrl"
                  class="hidden md:block w-full h-full object-cover"
                />
              </div>
              <img
                v-else
                src="@/assets/images/avatar.png"
                class="w-20 hidden md:block"
              />

              <div
                class="w-max font-sans-500 mt-4 text-2xl"
                v-if="this.$route.name == 'Getlist'"
              >
                {{ user.fullName }}
              </div>
              <div class="text-2xl font-sans-700 my-2">{{ getlist.title }}</div>
              <div class="mb-4 font-sans-300">
                {{ getlist.eventDate }}
              </div>
              <div
                class="flex items-center cursor-pointer"
                v-if="this.$route.name == 'Getlist'"
                @click="toggleModal(getlist)"
              >
                <img class="w-6" src="@/assets/images/settings.svg" alt="" />
                <span class="font-sans-700 text-lg pl-2">Settings</span>
              </div>
              <div class="font-sans-300 text-sm mt-4">
                {{ getlist.message }}
              </div>
            </div>
          </div>
        </div>
        <div v-else class="flex items-center mr-10">
          <div @click="goBack()" class="mr-6 w-3 cursor-pointer">
            <img class="w-full" src="@/assets/images/back.svg" alt="" />
          </div>
          <div class="w-max font-sans-500 text-2xl">
            {{ routeName }}
          </div>
        </div>
        <div
          class="mt-4 font-sans-500 pl-6 space-y-4"
          v-if="routeName == 'Find Gifts'"
        >
          <router-link to="/find-gifts">
            <span> Stores </span>
          </router-link>
          <router-link to="/find-gifts/gift-guides">
            <span> Gift Guides </span>
          </router-link>
        </div>
      </div>
      <div class="w-full lg:ml-8">
        <router-view v-slot="{ Component }">
          <transition name="scale" mode="out-in">
            <component
              :is="Component"
              :getlist="getlist"
              @refresh="getGetlist"
            ></component>
          </transition>
        </router-view>
      </div>
    </div>

    <AppModal
      @close="toggleModal"
      :modalActive="modalActive"
      :title="modalData.title"
    >
      <div v-if="modalData.getlist">
        <form class="bg-white rounded px-4 pb-6" @submit.prevent="submitForm">
          <AppInput
            title="Getlist Title"
            :required="true"
            v-model="modalData.getlist.title"
          />
          <AppInput
            title="Event Date"
            type="date"
            :required="true"
            v-model="modalData.getlist.eventDate"
          />
          <div class="flex items-center space-x-4">
            <div class="custom-input">
              <span class="font-sans-500 text-sm">Getlist Image</span>
              <div
                @click="triggerUpload"
                class="
                  cursor-pointer
                  text-standard-grey
                  border-getly-yellow border-2
                  rounded-full
                  font-semibold
                  select-none
                  px-6
                  py-2
                  w-max
                "
              >
                Choose File
              </div>
              <input
                @change="uploadImage"
                type="file"
                class="hidden"
                id="getlist-image"
              />
            </div>
            <div class="w-20 h-20 overflow-hidden" v-if="modalData.image">
              <img
                :src="modalData.image"
                class="w-full h-full object-contain"
              />
            </div>
          </div>
          <AppInput
            title="Short message"
            type="textarea"
            v-model="modalData.getlist.message"
          />
          <div class="flex items-center space-x-3 mb-10">
            <h3>Privacy Settings:</h3>
            <div class="flex items-center space-x-2">
              <input
                class="cursor-pointer"
                type="radio"
                name="privacy"
                id="public"
                v-model="modalData.getlist.privacy"
                value="0"
              />
              <label class="cursor-pointer" for="public"> Public </label>
            </div>
            <div class="flex items-center space-x-2">
              <input
                class="cursor-pointer"
                type="radio"
                name="privacy"
                id="private"
                v-model="modalData.getlist.privacy"
                value="1"
              />
              <label class="cursor-pointer" for="private"> Private </label>
            </div>
          </div>
          <div class="w-52 mx-auto mt-4 space-y-6">
            <AppButton>
              {{ modalData.status == "new" ? "Create" : "Update" }} Getlist
            </AppButton>

            <p
              @click="deleteGetlist(modalData.getlist.id)"
              class="text-center cursor-pointer"
            >
              Delete Getlist
            </p>
          </div>
        </form>
      </div>
    </AppModal>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Loader from "@/components/Loader.vue";
import { computed, onMounted, ref } from "@vue/runtime-core";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import AppModal from "@/components/AppModal.vue";
import AppInput from "@/components/Input.vue";
import AppButton from "@/components/AppButton.vue";
import AppButtonLight from "@/components/AppButtonLight.vue";
import { UpdateGetlistDTO } from "@/models/dtos/update-getlist-dto";
import notificationService from "@/services/notification.service";

export default {
  components: {
    AppModal,
    AppInput,
    AppButton,
    AppButtonLight,
    Loader,
    Header,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const getlist = ref();
    const modalActive = ref(false);
    const modalData = ref({
      title: "Getlist Details",
      image: "",
      getlist: null,
    });

    const loading = computed(() => store.state.loading.loading);
    const user = computed(() => store.state.auth.user);

    const toggleModal = (getlist) => {
      if (getlist) {
        modalData.value.image = getlist.imageUrl;
        modalData.value.getlist = getlist;
      }
      modalActive.value = !modalActive.value;
    };

    const goToGetlists = () => {
      router.push({ name: "Getlists" });
    };

    const triggerUpload = () => {
      const getlistImage = document.getElementById("getlist-image");
      getlistImage.click();
    };

    const uploadImage = async (event) => {
      const image = event.target.files[0];
      if (image) {
        modalData.value.image = URL.createObjectURL(image);
        modalData.value.getlist.image = image;
      }
    };

    const submitForm = async () => {
      await store.dispatch(
        "getlists/update",
        new UpdateGetlistDTO(modalData.value.getlist)
      );
      modalActive.value = false;
      notificationService.success("Getlist updated successfully");
    };

    const deleteGetlist = async (id) => {
      await store.dispatch("getlists/delete", id);
      modalActive.value = false;
      notificationService.success("Getlist deleted successfully");
      router.push({ name: "Getlists" });
    };

    const getGetlist = async () => {
      getlist.value = await store.dispatch("getlists/get", route.params.id);
      if (getlist.value.title) {
        document.title = getlist.value.title;
      } else {
        document.title = "Getlist not found";
      }
    };

    onMounted(async () => {
      await getGetlist();
    });

    return {
      user,
      loading,
      getlist,
      modalData,
      modalActive,
      getGetlist,
      uploadImage,
      triggerUpload,
      toggleModal,
      submitForm,
      deleteGetlist,
      goToGetlists,
    };
  },
  data() {
    return {
      routeName: this.$route.matched[0].name,
      openPanel: false,
    };
  },
  methods: {
    goBack() {
      this.$router.push("/home");
    },
  },
};
</script>

<style scoped>
a {
  @apply transition-all flex flex-col lg:flex-row items-center cursor-pointer lg:py-3 lg:px-6;
}

a > span {
  @apply font-bold md:text-sm text-xs font-sans-700 whitespace-nowrap;
}

.router-link-exact-active {
  @apply lg:bg-dashboard-yellow lg:rounded-lg;
}
</style>
