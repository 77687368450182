import { httpService } from "@/services/http.service"
import { Pagination } from "@/models/pagination"
import { GiftCard } from "@/models/gift-card"

const defaultState = () => {
  return {
    list: Pagination.prototype.createEmpty()
  }
}

const state = defaultState()

const actions = {
  create: async ({ dispatch }, data) => {
    const response = await httpService.post("gift-cards", data)
    return response.data.message;
  },
  getAll: async ({ commit }) => {
    const response = await httpService.get("gift-cards", { params: { received: true } })
    commit('SET_GIFTCARDS', response.data.data)
  },
  get: async (context, data) => {
    const response = await httpService.get(`gift-cards/${data}`)
    return new GiftCard(response.data.data)
  },
  redeem: async (context, data) => {
    const response = await httpService.post(`gift-cards/${data}/redeem`)
    return response.data.message
  },
  update: async ({ dispatch }, data) => {
    const response = await httpService.post(`gift-cards/${data.get('id')}`, data)
    return response.data.message
  },
  delete: async ({ dispatch }, data) => {
    const response = await httpService.delete(`gift-cards/${data}`)
    return response.data.message
  }
}

const mutations = {
  SET_GIFTCARDS: (state, giftCards) => {
    const paginatedList = new Pagination(giftCards, 'giftCards/getAll')
    paginatedList.data = paginatedList.data.map((giftCard) => new GiftCard(giftCard))
    state.list = paginatedList
  },
  RESET: (state) => {
    Object.assign(state, defaultState())
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}