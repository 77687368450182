import store from '@/store';
import authService from '../services/auth.service'

const authGuard = async (to, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    const token = authService.getToken();
    if (token) {
      try {
        store.dispatch('auth/verifyUser')
        next()
      } catch {
        authService.removeToken()
        next({ name: 'Login' })
      }
    } else {
      next({ name: 'Login' })
    }
  } else {
    next()
  }
}

export default authGuard