<template>
  <div
    v-if="loading"
    class="
      absolute
      z-50
      h-screen
      w-screen
      bg-getly-yellow bg-opacity-80
      flex
      justify-center
      items-center
    "
  >
    <img src="@/assets/images/logo.svg" alt="" class="animate-pulse" />
  </div>
</template>

<script>
import { useStore } from "vuex";
import { computed } from "@vue/runtime-core";

export default {
  setup() {
    const store = useStore();
    const loading = computed(() => store.state.loading.loading);

    return { loading };
  },
};
</script>

<style></style>
