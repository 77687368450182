<template>
  <div
    v-show="modalActive"
    class="font-sans-500 modal fixed top-0 left-0 w-full h-full bg-white bg-opacity-40 grid place-items-center z-10 text-standard-grey"
  >
    <div class="bg-white rounded-xl shadow-xl w-full md:w-3/5">
      <div v-if="type == 'normal'" class="pt-0 p-6 px-8">
        <div @click="close" class="flex justify-end cursor-pointer p-6">
          <img src="@/assets/images/close.svg" alt="" />
        </div>
        <div class="font-sans-700 text-2xl capitalize text-center mb-4">
          {{ title }}
        </div>

        <div>
          <slot />
        </div>
      </div>
      <div v-else class="overflow-hidden rounded-xl grid grid-cols-2 gap-x-6">
        <div class="bg-dashboard-yellow h-full p-6">
          <slot name="side" />
        </div>
        <div>
          <div class="flex items-center justify-between">
            <div class="font-sans-700 text-lg capitalize">
              {{ title }}
            </div>
            <div @click="close" class="cursor-pointer p-6">
              <img src="@/assets/images/close.svg" alt="" />
            </div>
          </div>
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    modalActive: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "normal",
    },
    title: {
      type: String,
    },
  },
  setup(props, { emit }) {
    const close = () => {
      emit("close");
    };

    return {
      close,
    };
  },
};
</script>

<style scoped></style>
