import { User } from "@/models/user"
import authService from "@/services/auth.service"
import { httpService } from "@/services/http.service"

const defaultState = () => {
  return {
    user: authService.getUser()
  }
}

const state = defaultState();

const actions = {
  login: async ({ dispatch }, data) => {
    const response = await httpService.post('signin', data)
    await authService.setToken(response.data.data.token)
    await dispatch("verifyUser")
  },
  register: async ({ commit }, data) => {
    await httpService.post('signup', data)
  },
  forgotPassword: async (context, data) => {
    await httpService.post('forgot-password', data)
  },
  resetPassword: async (context, data) => {
    await httpService.post('reset-password', data)
  },
  verifyEmail: async (context) => {
    const response = await httpService.post('verify-email-address')
    return response.data.message;
  },
  resendVerificationLink: async (context, _data) => {
    const response = await httpService.get('verify-email-address', { params: _data })
    return response.data.message;
  },
  changePassword: async (context, data) => {
    await httpService.patch('auth/change-password', data)
  },
  updateAvatar: async ({ dispatch }, data) => {
    await httpService.post('user', data)
    dispatch('verifyUser')
  },
  updateUser: async ({ dispatch }, data) => {
    await httpService.patch('user', data)
    dispatch('verifyUser')
  },
  verifyUser: async ({ commit }) => {
    const response = await httpService.get('user', { params: { _hideLoader_: true } })
    authService.setUser(response.data.data)
    commit('SET_USER', response.data.data)
  },
  logout: ({ dispatch }) => {
    httpService.delete('logout')
    setTimeout(() => {
      authService.removeToken()
      authService.removeUser()
      dispatch('resetState', {}, { root: true })
    }, 100);
  },
}

const mutations = {
  SET_USER: (state, user) => {
    state.user = new User(user)
  },
  REMOVE_USER: (state) => {
    state.user = null
  },
  RESET: (state) => {
    Object.assign(state, defaultState())
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}