export class Wallet {
  constructor(wallet) {
    this.currencyCode = wallet.currency;
    this.currentBalance = Number(wallet.current_balance);
    this.previousBalance = Number(wallet.previous_balance);
  }

  get currency() {
    switch (this.currencyCode) {
      case 'NGN':
        return '₦'
      case 'USD':
        return '$'
      default:
        return '₦'
    }
  }
}