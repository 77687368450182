<template>
  <div
    class="
      flex
      lg:block
      justify-between
      items-center
      bg-dashboard-yellow
      lg:bg-white
      shadow-md
      lg:shadow-none
      fixed
      lg:relative
      right-0
      bottom-0
      w-full
      p-4
      py-6
      lg:p-0
    "
  >
    <router-link
      to="/home"
      class="flex flex-col lg:flex-row items-center cursor-pointer"
    >
      <img class="md:w-8 w-6" src="@/assets/images/home.svg" alt="" />
      <span
        class="
          font-bold
          lg:ml-4
          md:text-sm
          text-xs
          font-sans-700
          whitespace-nowrap
        "
      >
        Home
      </span>
    </router-link>
    <router-link
      to="/getlists"
      class="flex flex-col lg:flex-row items-center lg:mt-7 cursor-pointer"
    >
      <img class="md:w-8 w-6" src="@/assets/images/Vector.svg" alt="" />
      <span
        class="
          font-bold
          lg:ml-4
          md:text-sm
          text-xs
          font-sans-700
          whitespace-nowrap
        "
      >
        Getlists
      </span>
    </router-link>
    <router-link
      to="/gift-cards"
      class="flex flex-col lg:flex-row items-center lg:mt-7 cursor-pointer"
    >
      <img class="md:w-8 w-6" src="@/assets/images/gift.svg" alt="" />
      <span
        class="
          font-bold
          lg:ml-4
          md:text-sm
          text-xs
          font-sans-700
          whitespace-nowrap
        "
      >
        Gift Cards
      </span>
    </router-link>
    <router-link
      to="/wallets"
      class="flex flex-col lg:flex-row items-center lg:mt-7 cursor-pointer"
    >
      <img class="md:w-8 w-6" src="@/assets/images/credit-card.svg" alt="" />
      <span
        class="
          font-bold
          lg:ml-4
          md:text-sm
          text-xs
          font-sans-700
          whitespace-nowrap
        "
      >
        Wallet
      </span>
    </router-link>
    <router-link
      to="/settings"
      class="flex flex-col lg:flex-row items-center lg:mt-7 cursor-pointer"
    >
      <img class="md:w-8 w-6" src="@/assets/images/settings.svg" alt="" />
      <span
        class="
          font-bold
          lg:ml-4
          md:text-sm
          text-xs
          font-sans-700
          whitespace-nowrap
        "
      >
        Settings
      </span>
    </router-link>
    <button
      @click="logout"
      class="
        flex flex-col
        lg:flex-row
        items-center
        lg:mt-7
        cursor-pointer
        w-max
      "
    >
      <img class="w-8" src="@/assets/images/log-out.svg" alt="" />
      <span
        class="
          font-bold
          lg:ml-4
          md:text-sm
          text-xs
          font-sans-700
          whitespace-nowrap
        "
      >
        Log out
      </span>
    </button>
  </div>
</template>

<script>
import authService from "@/services/auth.service";
import { defineComponent } from "vue";
export default defineComponent({
  name: "AppLayoutLinks",
  computed: {
    isLoggedIn: function () {
      return this.$store.getters.isLoggedIn;
    },
  },
  methods: {
    logout: async function () {
      await this.$store.dispatch("auth/logout");
      authService.removeToken()
      this.$router.push("/login");
    },
  },
  components: {},
  setup() {
    return {};
  },
});
</script>

<style scoped>
a {
  @apply transition-all
}
.router-link-active {
  @apply lg:py-3 lg:px-6 lg:bg-getly-yellow lg:rounded-l-lg;
}
</style>