const metaWrapper = (to, from) => {
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => (r.meta && r.meta.title) || r.name);

  const previousNearestWithMeta = from.matched
    .slice()
    .reverse()
    .find((r) => (r.meta && r.meta.metaTags) || r.name);

  if (nearestWithTitle) {
    document.title = `${nearestWithTitle.meta.title || nearestWithTitle.name} | Getly`;
  } else if (previousNearestWithMeta) {
    document.title = `${previousNearestWithMeta.meta.title || previousNearestWithMeta.name} | Getly`;
  }
}

export default metaWrapper