import { Bank } from "@/models/bank"
import { Wallet } from "@/models/wallet"
import { httpService } from "@/services/http.service"

const defaultState = () => {
  return {
    wallet: null,
    banks: []
  }
}

const state = defaultState();

const actions = {
  withdraw: async ({ dispatch }, data) => {
    const response = await httpService.post("wallet", data)
    await dispatch("getAll")
    return response.data.message;
  },
  get: async ({ commit }) => {
    const response = await httpService.get(`wallet`)
    commit("SET_WALLET", response.data.data)
  },
  verifyTransaction: async ({ dispatch }, data) => {
    const response = await httpService.patch(`wallet`, data)
    return response.data.message
  },
  fund: async ({ dispatch }, data) => {
    const response = await httpService.put(`wallet`, data)
    return response.data.data.wallet.payment_link
  },
  getBanks: async ({ commit }) => {
    const response = await httpService.get(`banks`)
    commit("SET_BANKS", response.data.data)
  },
  verifyBankAccount: async ({ commit }, data) => {
    const response = await httpService.get('kyc/bank', { params: data })
    return response.data.data;
  },
}

const mutations = {
  SET_WALLET: (state, wallet) => {
    state.wallet = new Wallet(wallet)
  },
  SET_BANKS: (state, banks) => {
    state.banks = banks.map((bank) => new Bank(bank))
  },
  RESET: (state) => {
    Object.assign(state, defaultState())
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}