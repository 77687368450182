import OnboardingLayout from "@/layouts/AppLayoutOnboard.vue"
import DashboardLayout from "@/layouts/AppLayoutDashboard.vue"
import AltDashboardLayout from "@/layouts/AppLayoutDashboard2.vue"

const loadPage = (pagePath) => {
  return () => import(`../views/${pagePath}.vue`)
}

const routes = [
  {
    path: '/',
    name: 'Welcome',
    component: loadPage('auth/welcome'),
    meta: {
      layout: OnboardingLayout
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: loadPage('auth/login'),
    meta: {
      layout: OnboardingLayout
    }
  },
  {
    path: '/new-password/:token',
    name: 'New Password',
    component: loadPage('auth/new-password'),
    meta: {
      layout: OnboardingLayout
    }
  },
  {
    path: '/signup',
    name: 'Signup',
    component: loadPage('auth/signup'),
    meta: {
      layout: OnboardingLayout
    }
  },
  {
    path: '/reset',
    name: 'Reset',
    component: loadPage('auth/reset'),
    meta: {
      layout: OnboardingLayout
    }
  },
  {
    path: '/verify-email/:token',
    name: 'Verify Email',
    component: loadPage('auth/verify-email'),
    meta: {
      layout: OnboardingLayout
    }
  },
  {
    path: '/home',
    name: 'Home',
    component: loadPage('dashboard/home-page'),
    meta: {
      title: 'Dashboard',
      requiresAuth: true,
      layout: DashboardLayout
    }
  },
  {
    path: '/getlists',
    name: 'Getlists',
    component: loadPage('dashboard/getlists'),
    meta: {
      requiresAuth: true,
      layout: DashboardLayout
    }
  },
  {
    path: '/getlists/:id',
    name: 'Getlist',
    component: loadPage('dashboard/getlist'),
    meta: {
      requiresAuth: true,
      layout: AltDashboardLayout
    }
  },
  {
    path: '/gift-cards',
    name: 'Gift Cards',
    component: loadPage('dashboard/gift-cards'),
    meta: {
      requiresAuth: true,
      layout: DashboardLayout
    }
  },
  {
    path: '/wallets',
    name: 'Wallets',
    component: loadPage('dashboard/wallets'),
    meta: {
      requiresAuth: true,
      layout: DashboardLayout
    }
  },
  {
    path: '/settings',
    name: 'Settings',
    component: loadPage('dashboard/settings'),
    children: [
      {
        path: '',
        component: loadPage('dashboard/sub-settings/edit-profile'),
        meta: { title: 'Edit Profile' }
      },
      {
        path: 'change-password',
        component: loadPage('dashboard/sub-settings/change-password'),
        meta: { title: 'Change Password' }
      },
      {
        path: 'change-pin',
        component: loadPage('dashboard/sub-settings/change-pin'),
        meta: { title: 'Change Pin' }
      },
      {
        path: 'account-details',
        component: loadPage('dashboard/sub-settings/account-details'),
        meta: { title: 'Account Details' }
      },
    ],
    meta: {
      requiresAuth: true,
      layout: DashboardLayout
    }
  },
  {
    path: '/find-gifts',
    name: 'Find Gifts',
    component: loadPage('dashboard/find-gifts'),
    children: [
    {
      path: '',
      name: 'Stores',
      component: loadPage('dashboard/sub-find-gifts/stores'),
    },
    {
      path: 'gift-guides',
      name: 'Gift Guides',
      component: loadPage('dashboard/sub-find-gifts/gift-guides'),
    }
    ],
    meta: {
      requiresAuth: true,
      layout: AltDashboardLayout
    },

  },
  {
    path: '/fulfill-wish',
    name: 'Fulfill a Wish',
    component: loadPage('dashboard/fulfill-wish'),
    meta: {
      requiresAuth: true,
      layout: AltDashboardLayout
    }
  },
  {
    path: '/wishlist/:id',
    name: 'Wishlist',
    component: loadPage('dashboard/wishlist'),
    meta: {
      layout: AltDashboardLayout
    }
  },
  {
    path: '/:pathMatch(.*)*', name: '404', component: loadPage('auth/login'),
    meta: {
      title: 'Page Not Found',
      layout: OnboardingLayout
    }
  },
]

export default routes