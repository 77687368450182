<template>
  <div class="w-full flex flex-col text-standard-grey">
    <label class="mb-2 text-sm font-sans-500">
      {{ title }}
    </label>
    <div
      class="
        rounded-xl
        px-2
        border-2 border-light-grey border-opacity-30
        mb-4
        flex
        items-center
      "
      :class="{ 'rounded-full': variation === 'search' }"
    >
      <img
        src="@/assets/images/search-icon.png"
        v-if="variation === 'search'"
        class="w-5"
      />
      <textarea
        class="outline-none w-full bg-transparent py-2 font-sans-300"
        autocomplete="off"
        v-if="type == 'textarea'"
        rows="5"
        :value="modelValue"
        :type="type"
        :name="inputName"
        :disabled="disabled"
        :required="required"
        :placeholder="placeholder"
        @input="$emit('update:modelValue', $event.target.value)"
      ></textarea>
      <input
        v-else
        class="outline-none w-full bg-transparent p-3 font-sans-300"
        autocomplete="off"
        ref="inputType"
        :value="modelValue"
        :type="type"
        :name="inputName"
        :disabled="disabled"
        :required="required"
        :placeholder="placeholder"
        :max="max"
        :min="min"
        :maxlength="maxlength"
        :minlength="minlength"
        @input="$emit('update:modelValue', $event.target.value)"
      />
      <img
        @click="toggleVisibility"
        v-if="variation === 'password'"
        class="w-5 cursor-pointer"
        src="@/assets/images/eye.svg"
        alt=""
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "InputComponent",
  props: {
    modelValue: {
      required: false,
      default: "",
    },
    variation: {
      type: String,
    },
    title: {
      type: String,
    },
    type: {
      type: String,
      default: "text",
    },
    placeholder: {
      type: String,
    },
    maxlength: {
      type: String,
    },
    minlength: {
      type: String,
    },
    max: {
      type: String,
    },
    min: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    inputName() {
      return this.$props.title?.toLocaleLowerCase().replaceAll(" ", "-");
    },
  },
  methods: {
    toggleVisibility() {
      const x = this.$refs.inputType;

      if (x.type == "password") {
        x.type = "text";
      } else {
        x.type = "password";
      }
    },
  },
};
</script>
