export class VirtualCard {
  constructor(virtualCard) {
    this.balance = Number(virtualCard.balance);
    this.brand = virtualCard.brand;
    this.currencyCode = virtualCard.currency;
    this.cvv = virtualCard.cvv;
    this.pin = virtualCard.pin;
    this.status = virtualCard.status;
    this.type = virtualCard.type;
    this.cardNumber = virtualCard.card_number;
    this.cardPan = virtualCard.card_pan;
    this.expiryMonth = virtualCard.expiry_month;
    this.expiryYear = virtualCard.expiry_year;
    this.lastFour = virtualCard.last_four;
    this.nameOnCard = virtualCard.name_on_card;
    this.billingAddress = new BillingAddress(virtualCard.billing_address)
  }

  get currency() {
    switch (this.currencyCode) {
      case 'NGN':
        return '₦'
      case 'USD':
        return '$'
      default:
        return '₦'
    }
  }
}

class BillingAddress {
  constructor(billingAddress) {
    this.state = billingAddress.state
    this.postalCode = billingAddress.postal_code
    this.street = billingAddress.street
    this.country = billingAddress.country
    this.city = billingAddress.city
  }

  get fullAddress() {
    return `${this.street}, ${this.city}, ${this.state}, ${this.country}`
  }
}