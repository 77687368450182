import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store';
import routes from './routes';
import authGuard from './auth-guard';
import metaWrapper from './meta-wrapper';


const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {
    authGuard(to, next)
    metaWrapper(to, from)
})

export default router