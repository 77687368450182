<template>
  <button
    class="rounded-full font-semibold select-none px-4 py-3 w-full border-getly-yellow border-2 hover:bg-getly-blue hover:text-white"
    :type="operation"
    :disabled="disabled"
    @click="$emit('click', $event)"
  >
    <span class="flex items-center justify-center gap-x-1">
      <slot />
    </span>
  </button>
</template>

<script>
export default {
  name: "ButtonComponent",
  props: {
    operation: {
      type: String,
      default: "submit",
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    smaller: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style scoped></style>
