export class Transaction {
  constructor(transaction) {
    this.amount = Number(transaction.amount);
    this.channel = transaction.channel;
    this.id = transaction.id;
    this.narration = transaction.narration;
    this.status = transaction.status;
    this.type = transaction.type;
    this.meta = new Meta(transaction.meta);
    this.createdAt = transaction.created_at;
    this.updatedAt = transaction.updated_at;
    this.deletedAt = transaction.deleted_at;
  }

  get initials() {
    const [first, second] = this.narration.split(" ");
    return `${first.charAt(0)}${second ? second.charAt(0) : ''}`;
  }

  get formattedChannel() {
    return this.channel.replaceAll("-", " ")
  }

  get typeSign() {
    switch (this.type) {
      case 'debit':
        return '-'
      case 'credit':
        return '+'
      default:
        return '+'
    }
  }
}

class Meta {
  constructor(meta) {
    this.status = meta.status;
    this.message = meta.message;
  }
}