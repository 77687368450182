<template>
  <div>
    <Loader />
    <div class="lg:hidden pt-8 pl-8 md:pl-32">
      <img src="@/assets/images/logo.svg" class="w-28" />
    </div>
    <div
      class="
        h-screen
        bg-onboard bg-no-repeat bg-cover bg-center bg
        text-standard-grey
        font-sans-500
      "
    >
      <div class="flex justify-center items-center">
        <div class="hidden lg:block w-1/2">
          <img
            src="@/assets/images/Art.svg"
            class="xl:w-96 lg:w-40 md:w-40 w-40 mx-auto"
          />
        </div>
        <div
          class="
            bg-white
            min-h-screen
            w-full
            lg:w-1/2
            flex
            justify-center
            items-center
          "
        >
          <div class="px-8 md:px-32 lg:px-40 w-full">
            <router-view v-slot="{ Component }">
              <transition name="scale" mode="out-in">
                <component :is="Component"></component>
              </transition>
            </router-view>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NotificationPanel from "@/components/NotificationPanel.vue";
import Header from "@/components/Header.vue";
import Loader from "@/components/Loader.vue";
import AppLayoutLinks from "@/components/AppLinks.vue";

export default {
  components: {
    Header,
    Loader,
    AppLayoutLinks,
    NotificationPanel,
  },
};
</script>


