import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/tailwind.css'

import Toast from 'vue-toastification'
import 'vue-toastification/dist/index.css'

const options = {
    position: "top-right",
    timeout: 3000,
    closeOnClick: true,
    pauseOnFocusLoss: true,
    pauseOnHover: true,
    draggable: true,
    draggablePercent: 0.6,
    showCloseButtonOnHover: false,
    hideProgressBar: true,
    closeButton: "button",
    icon: true,
    rtl: false
};


import AppLayout from './layouts/AppLayout.vue'

const app = createApp(App)

app.use(store)
app.use(router)
app.component('AppLayout', AppLayout)
app.use(Toast, options)
app.mount('#app')

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.needsAuth)) {
        if (!store.getters.loggedin){
            next({
                name: 'signin',
            })
        }
        else{
            next({
                name: 'home'
            })
        }
    }
    else{
        next()
    }
})