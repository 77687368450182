<template>
  <div>
    <div class="shadow-md">
      <div class="" v-if="!user?.kycVerified">
        <div class="bg-getly-yellow h-12 flex items-center justify-center">
          <div class="flex items-center font-sans-300">
            <img src="@/assets/images/alert-circle.svg" class="h-6 pr-2" />
            <span
              >Kindly input and verify your BVN to carry out any transactions
              <router-link
                to="/settings/account-details"
                class="font-bold font-sans-500 underline p-0"
                >here</router-link
              ></span
            >
          </div>
        </div>
      </div>
      <div
        class="mb-10 flex justify-between py-6 max-w-screen-xl mx-auto w-11/12"
      >
        <img
          src="@/assets/images/logo.svg"
          @click="goHome"
          class="md:w-20 w-16 cursor-pointer"
        />
        <div class="flex items-center md:flex-row flex-row-reverse">
          <div class="cursor-pointer relative">
            <img
              @click="openPanel = !openPanel"
              src="@/assets/images/bell.svg"
              class="w-8"
            />

            <span
              class="
                animate-ping
                absolute
                top-1
                right-0.5
                block
                h-3
                w-3
                rounded-full
                ring-2 ring-yellow-400
                bg-getly-yellow
              "
            ></span>
            <span
              class="
                absolute
                top-1
                right-0.5
                rounded-full
                h-3
                w-3
                bg-getly-yellow
              "
            ></span>
            <div class="" v-show="openPanel">
              <NotificationPanel />
            </div>
          </div>
          <div class="flex items-center ml-8">
            <div
              v-if="user.avatarUrl"
              class="
                w-12
                h-12
                rounded-full
                mr-4
                overflow-hidden
                border-2 border-getly-yellow
              "
            >
              <img
                :src="user.avatarUrl"
                class="md:block w-full h-full object-cover"
              />
            </div>
            <img
              v-else
              src="@/assets/images/avatar.png"
              class="w-12 mr-4 hidden md:block"
            />
            <span class="text-standard-grey hidden md:block font-sans-500">
              {{ user.fullName }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NotificationPanel from "@/components/NotificationPanel.vue";
import { ref } from "@vue/reactivity";
import { useStore } from "vuex";
import { computed } from "@vue/runtime-core";
import { useRouter } from "vue-router";

export default {
  components: {
    NotificationPanel,
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    const openPanel = ref(false);
    const user = computed(() => store.state.auth.user);
    const loading = computed(() => store.state.loading.loading);

    const goHome = () => {
      router.push("/home");
    };

    return { openPanel, user, loading, goHome };
  },
};
</script>

<style></style>
