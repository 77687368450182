<template>
  <component :is="layout">
    <slot />
  </component>
</template>

<script>
import { shallowRef } from "@vue/reactivity";
import AppLayoutDefault from "./AppLayoutDefault.vue";

export default {
  name: "AppLayout",
  data: () => ({
    layout: shallowRef(AppLayoutDefault),
  }),
  watch: {
    $route: {
      immediate: true,
      async handler(route) {
        try {
          const component = await import(`@/layouts/${route.meta.layout}.vue`);
          this.layout =
            shallowRef(component?.default) || shallowRef(AppLayoutDefault);
        } catch (e) {
          this.layout = shallowRef(AppLayoutDefault);
        }
      },
    },
  },
};
</script>

<style scoped></style>
